import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
//import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                {/** Qué es¿? */}
                <Title>Les 500 imprescindibles per a Tarragona</Title>
                <Text>
                    Et presentem el llibre de Les 500 preguntes imprescindibles per accedir a la
                    Guàrdia Urbana de Tarragona.
                    <br />
                    <br />
                    <strong>Amb mòdul gratuït disponible durant un any.</strong>
                    <br />
                    <br />
                    Dins d'aquest llibre trobareu 500 preguntes amb una única resposta, moltes
                    extretes d’exàmens anteriors del municipi i altres que considerem importants i
                    susceptibles de ser preguntades en aquesta prova de 2023.
                    <br />
                    <br />
                    S'ha elaborat seguint el temari que conformen les bases oficials d’accés a la
                    Guardia Urbana de Tarragona.
                    <br />
                    <br />
                    Un recopilatori preguntes amb el que obtindràs un coneixement en profunditat del
                    temari i del municipi.
                    <br />
                    <br />
                    Les preguntes imprescindibles per aprovar amb nota la part específica de
                    l’exercici teòric que consisteix en un qüestionari de preguntes tipus test.
                    <br />
                    <br />
                    Un complement perfecte per profunditzar en el teu procés d'estudi, una manera
                    diferent d'aprendre.
                    <br />
                    <br />
                    Totes les preguntes contenen la resposta correcta.
                    <br />
                    <br />
                    Trobaràs tot el necessari segons les bases del temari:
                    <br />
                    <br />
                    - Coneixement del municipi
                    <br />
                    <br />
                    - Preguntes del temari específic
                    <br />
                    <br />
                    - Ordenances
                    <br />
                    <br />
                    - Curiositats i molt més.
                    <br />
                    <br />
                    Amb la compra del llibre t'habilitem de manera{" "}
                    <strong>TOTALMENT GRATUÏTA</strong> durant un any el mòdul de les 500 preguntes
                    en format test per practicar el que has estudiat i posar-te a prova!
                    <br />
                    <br />
                    Un mòdul que podràs fer servir a través de l'aplicació iGuB en qualsevol moment
                    i allà on vulguis.
                    <br />
                    <br />
                    Vols ser agent de la Guardia Urbana de Tarragona?
                    <br />
                    <br />
                    Fes-te amb aquest llibre únic i no et quedis enrere!
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
